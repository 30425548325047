import styled, { css, keyframes } from 'styled-components';
import { stoomlinkColors as colors } from 'stoomlink-commons-ui/colors';
import { colors as themeColors } from '../../theme';

export const Button = styled.a`
  display: inline-flex;
  ${({ isGrouped }) => isGrouped && css`
    flex-grow: 0;
    flex-basis: 50%;
  `}
  position: relative;
  margin: 0;
  padding: ${({ isGrouped }) => isGrouped ? '13px 15px 29px 13px' : '16px 58px 16px 15px'};
  /* height: ${({ isGrouped }) => isGrouped ? '64px' : '54px'}; */
  border: ${({ theme, $type }) => ($type === 'tertiary' ? `1px solid ${theme.colors?.secondary || themeColors.secondary}` : 0)};
  background-color: ${({ theme, $type }) => {
    switch ($type) {
      case 'secondary':
        return theme.colors?.primaryShade2 || themeColors.primaryShade2;
      case 'tertiary':
      case 'ghost':
        return 'transparent';
      case 'danger':
        return 'hsl(350,80%,50%)';
      case 'primary':
      default:
        return (theme.colors?.secondary || themeColors.secondary);
    }
  }};
  color: ${({ theme, $type }) => {
    switch ($type) {
      case 'secondary':
      case 'tertiary':
      case 'ghost':
        return theme.colors?.secondary || themeColors.secondary;
      case 'primary':
      case 'danger':
      default:
        return (theme.colors?.primary || themeColors.primary);
    }
  }};
  font-family: 'HN Text';
  font-weight: 500;
  font-size: ${({ isGrouped }) => isGrouped ? '14px' : '16px'};
  line-height: 22px;
  text-align: left;
  text-decoration: none;
  appearance: none;
  cursor: pointer;
  outline: transparent solid 2px;
  outline-offset: -2px;
  box-sizing: border-box;
  transition: background-color 0.25s cubic-bezier(.2, 0, .38, .9), border 0.25s cubic-bezier(.2, 0, .38, .9), color 0.25s cubic-bezier(.2, 0, .38, .9), outline 0.12s cubic-bezier(.2, 0, .38, .9);

  ${({ hasIcon, isLoading, isGrouped }) => {
    if (hasIcon && !isLoading && !isGrouped) {
      return css`
        &::before {
          content: '→';
          flex-shrink: 0;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      `;
    }
  }}
  
  &:hover {
    background-color: ${({ theme, $type }) => {
    switch ($type) {
      case 'secondary':
        return colors.Gray200.hex;
      case 'ghost':
        return theme.colors?.primaryShade1 || themeColors.primaryShade1;
      case 'danger':
        return 'hsl(350,80%,40%)';
      case 'primary':
      case 'tertiary':
      default:
        return (theme.colors?.secondaryShade2 || themeColors.secondaryShade2);
    }
  }};
    color: ${({ theme, $type }) => {
    switch ($type) {
      case 'tertiary':
        return theme.colors?.primary || themeColors.primary;
      case 'primary':
      case 'secondary':
      case 'ghost':
      case 'danger':
      default:
        return null;
    }
  }};
  }
  &:active {
    background-color: ${({ theme, $type }) => {
    switch ($type) {
      case 'secondary':
        return colors.Gray300.hex;
      case 'ghost':
        return theme.colors?.primaryShade2 || themeColors.primaryShade2;
      case 'danger':
        return 'hsl(350,80%,30%)';
      case 'primary':
      case 'tertiary':
      default:
        return (colors.Gray600.hex);
    }
  }};
    border: ${({ $type }) => ($type === 'tertiary' ? '1px solid transparent' : 0)};
    color: ${({ theme, $type }) => {
    switch ($type) {
      case 'tertiary':
        return theme.colors?.primary || themeColors.primary;
      case 'primary':
      case 'secondary':
      case 'ghost':
      case 'danger':
      default:
        return null;
    }
  }};
  }
  &:focus {
    background-color: ${({ theme, $type }) => {
    switch ($type) {
      case 'secondary':
        return theme.colors?.primaryShade2 || themeColors.primaryShade2;
      case 'tertiary':
      case 'ghost':
        return 'transparent';
      case 'danger':
        return 'hsl(350,80%,50%)';
      case 'primary':
      default:
        return (theme.colors?.secondary || themeColors.secondary);
    }
  }};
    color: ${({ theme, $type }) => ($type === 'tertiary' ? theme.colors?.secondary || themeColors.secondary : null)};
    outline: ${({ theme }) => `${theme.colors?.secondary || themeColors.secondary} solid 2px`};
    &::after {
      border: ${({ theme }) => `1px solid ${theme.colors?.primary || themeColors.primary}`};
    }
  }
  &:disabled {
    border: ${({ theme, $type }) => ($type === 'tertiary' ? `1px solid ${colors.Gray300.hex}` : 0)};
    background-color: ${({ theme, $type }) => {
    switch ($type) {
      case 'tertiary':
        return 'transparent';
      case 'ghost':
        return (theme.colors?.primaryShade1 || themeColors.primaryShade1);
      case 'primary':
      case 'secondary':
      case 'danger':
      default:
        return theme.colors?.primaryShade2 || themeColors.primaryShade2;
    }
  }};
    color: ${({ theme, $type }) => {
    switch ($type) {
      case 'tertiary':
        return colors.Gray300.hex;
      case 'primary':
      case 'secondary':
      case 'ghost':
      case 'danger':
      default:
        return colors.Gray400.hex;
    }
  }};
    cursor: not-allowed;
  }
`;

export const LoadingContainer = styled.span`
  flex-shrink: 0;
  position: absolute;
  right: 20px;
  transform: translateY(2px);
  pointer-events: none;
`;

const infiniteRotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadingIndicator = styled.div`
  height: 16px;
  width: 16px;
  cursor: wait;
  border: 3px solid ${({ theme }) => (theme.colors?.primary || themeColors.primary)};
  border-radius: 50%;
  border-top-color: ${({ theme }) => (theme.colors?.secondary || themeColors.secondary)};
  animation: ${infiniteRotate} 1s linear;
  animation-iteration-count: infinite;
`;
