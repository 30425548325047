import styled from 'styled-components';
import { stoomlinkColors } from 'stoomlink-commons-ui/colors';

// =============================================================================
// Config
// =============================================================================
export const colors = {
  main: stoomlinkColors.Blue400.hex,
  accent: stoomlinkColors.Blue400.hex,
  green: stoomlinkColors.Green300.hex,

  mainLight: stoomlinkColors.Blue50.hex,
  accentLight: stoomlinkColors.Blue50.hex,

  lightText: stoomlinkColors.Gray300.hex,

  darkGrey: stoomlinkColors.Gray700.hex,
  grey: stoomlinkColors.Gray300.hex,
  lightGrey: stoomlinkColors.Gray100.hex,
  black: stoomlinkColors.black.hex,
  white: stoomlinkColors.white.hex,

  primary: stoomlinkColors.white.hex,
  primaryShade1: stoomlinkColors.Gray50.hex,
  primaryShade2: stoomlinkColors.Gray100.hex,
  primaryShade3: stoomlinkColors.Gray200.hex,
  secondary: stoomlinkColors.black.hex,
  secondaryShade1: stoomlinkColors.Gray900.hex,
  secondaryShade2: stoomlinkColors.Gray800.hex,
  highlight: stoomlinkColors.Blue400.hex,
  highlight1: '#551A8B',
  highlight2: stoomlinkColors.Green400.hex
};

// =============================================================================
// Base Styles
// =============================================================================
// Base Components Styles, extended accross the app in various components

export const ContainerStyle = styled.div`
  margin: 0 auto;
  width: 1240px;
  @media screen and (max-width: 1340px){
    width: 980px;
  }
  @media screen and (max-width: 980px){
    width: 80%;
  }
  @media screen and (max-width: 800px){
    width: 90%;
  }
  @media screen and (max-width: 667px){
    width: 85%;
  }
`;
