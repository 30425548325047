import { createGlobalStyle } from 'styled-components';
import { colors } from './index';

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    overflow-x: hidden !important;
    font-family: "HN Text" !important; /* FUCKING SUI AND ASS TYPOGRAPHIC IMPLEMENTATION */
    font-weight: 400;
    font-feature-settings: "ss01", "ss04", "ss08";
  }

  h1, h2, h3, h4, h5, h6 {
    /* FUCKING SUI AND ASS TYPOGRAPHIC IMPLEMENTATION */
    font-family: "HN Display";
  }

  p {
    /* FUCKING SUI AND ASS TYPOGRAPHIC IMPLEMENTATION */
    font-family: "HN Text" !important;
    font-weight: 400 !important;
  }

  .focus-main {
    color: ${colors.main};
  }

  .focus-accent {
    color: ${colors.accent};
  }

  .focus-light {
    color: ${colors.white};
  }
  .focus-green {
    color: ${colors.green}
  }

  .sr {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
    visibility: inherit;
    clip: rect(0,0,0,0);
  }
`;

export default GlobalStyle;
