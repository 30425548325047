import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxProvider } from 'react-scroll-parallax';

import GlobalStyle from '../../theme/global';
import FontStyle from '../../theme/fonts';

import Nav from '../../components/Nav';
import Banner from '../../components/Banner';

// TODO: Dynamically load Banner content

export default function Page({ children }) {
  return (
    <>
      <ParallaxProvider>
        <GlobalStyle />
        <FontStyle />
        <Nav />
        <Banner
          subtitle="Latest News"
          title="WE ARE HIRING! See our open positions here"
          link="http://belgianmobilitycompany.be/"
          target="_blank"
          rel="noreferrer"
        />
        {children}
      </ParallaxProvider>
    </>

  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
};
