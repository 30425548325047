import React from 'react';
import {
  Button as StyledButton,
  LoadingContainer, LoadingIndicator
} from './styledComponent';

const Button = (props) => {
  const {
    children, href, className, theme, type, kind, hasIcon, isLoading,
  } = props;

  return (
    <StyledButton
      href={href}
      theme={theme}
      className={className}
      as={type === 'link' ? 'a' : 'button'}
      hasIcon={hasIcon}
      isLoading={isLoading}
      $type={kind}
      {...props}
    >
      {children}
      {
        isLoading && (
          <LoadingContainer aria-hidden={true}>
            <LoadingIndicator />
          </LoadingContainer>
        )
      }
    </StyledButton>
  );
};

Button.defaultProps = {
  type: 'link',
  kind: 'primary',
  hasIcon: true,
  isLoading: false,
}

export default Button;
