import styled, { keyframes } from 'styled-components';
import { stoomlinkColors } from 'stoomlink-commons-ui/colors';

const rollOverTopToBottom = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
`;

export const OuterContainer = styled.a`
  display: flex;
  place-items: center;
  min-height: 64px;
  padding: 16px 0;
  position: sticky;
  top: 0;
  width: 100vw;
  background-color: rgba(${stoomlinkColors.black.rgb}, 0.72);
  font-family: "HN Display";
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: white;
  z-index: 10000;
  overflow: hidden;
  backdrop-filter: saturate(180%) blur(20px);
  transition: background-color 0.25s cubic-bezier(.2, 0, .38, .9);

  &::after {
    content: '';
    display: ${({ animate }) => (animate ? 'block' : 'none')};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    will-change: transform;
    animation: ${rollOverTopToBottom} 0.6s cubic-bezier(.2, .8, .4, 1);
    animation-delay: 0.4s;
    animation-fill-mode: both;
  }

  &:hover {
    background-color: rgba(${stoomlinkColors.Blue400.rgb}, 0.8);
  }
`;

export const Subtitle = styled.div`
  font-family: "HN Text";
  font-size: 12px;
  letter-spacing: 0.24px;
`;

export const Title = styled.span`
  letter-spacing: 0.24px;
  
  &:after {
    content: "→";
    margin-left: 12px;
    font-weight: 700;
  }
`;
