import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Container from '../../layouts/Container';

import {
  OuterContainer,
  Subtitle,
  Title,
} from './styledComponent';

const Banner = ({
  subtitle, title, link, target, rel,
}) => {
  // FOR REFERENCE
  // https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  const [hasMounted, setHasMounted] = React.useState(false);
  const [playAnimation, setPlayAnimation] = useState();

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  if (
    typeof window !== 'undefined'
    && window.sessionStorage.getItem('stmlk.website.bannerAnimation') !== 'true'
  ) {
    setPlayAnimation(true);
    window.sessionStorage.setItem('stmlk.website.bannerAnimation', 'true');
  }

  return (
    <OuterContainer as={target === '_blank' ? Link : 'a'} href={link} animate={playAnimation} target={target} rel={rel}>
      <Container>
        <Subtitle>
          {subtitle}
        </Subtitle>
        <Title>
          {title}
        </Title>
      </Container>
    </OuterContainer>
  );
};

Banner.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
};

export default Banner;
