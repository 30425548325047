import styled from 'styled-components';
import { ContainerStyle, colors } from '../../theme/index';

export const LogoContainer = styled.a`
  display: inline-flex !important;
  place-items: center;
  margin-right: 32px;
`;
export const Container = styled.nav`
  font-family: "HN Display";
  width: 100vw;
  padding: 16px 0;
  ${({ showMenu }) => showMenu && `
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30000;
  `}
`;

export const SectionLink = styled.a`
  display: block;
  margin: 0 12px;
`;

export const InnerContainer = styled(ContainerStyle)`

  @media (max-width: 1024px){
    justify-content: space-between;
  }
  display: flex;
  position: relative;

  a {
    position: relative;
    display: inline-block;
    color: black;
    font-size: 18px;
    font-weight: 500;
    transition: all .3s cubic - bezier(0.2, 0.8, 0.4, 1);
    text-decoration: none;
  }

  a.persona {
    color: ${colors.main};
  }

  a.external {
      padding-left: 0;

      &:after {
      content: "→";
      margin-left: 10px;
      font-weight: 700;
      font-size: 14px;
    }
  }
`;

export const LinkContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 0 32px;
    margin-left: auto;
    
    .otherLinks a {
      margin: 0 12px;
    }

    @media (max-width: 1024px){
      display: none;
      ${({ showMenu }) => ((showMenu) && `
          display: flex;
          margin: 0;
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: #000000;
          z-index: 20000;
          flex-direction: column;
          justify-content: center;
          div{
            margin-bottom: 24px;
            &:last-of-type{
              margin-bottom: 0;
            }
          }
          a, .otherLinks a {
            text-align: center;
            color: #ffffff;
            margin: 0;
            display: block;
            margin-bottom: 16px;
          }
        `)}
    }
`;

export const LinkBlock = styled.div`

`;

export const ButtonMenu = styled.button`
    display: none;
    appearance: none;
    @media (max-width: 1024px){
      display: block;
      outline: none;
      z-index: 30000;
      background: none;
      width: 30px;
      height: 30px;
      margin: 0;
      padding: 0;
      border: none;
      color: transparent;
      position: relative;
      &::before, &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: ${({ showMenu }) => (showMenu ? '#FFFFFF' : '#000000')};
        position: absolute;
        top: 50%;
        transition: all .4s cubic-bezier(.3,1,.3,1);
      };

      &::before {
        transform: ${({ showMenu }) => (showMenu ? 'translateY(-50%) rotate(45deg)' : 'translateY(-50%) rotate(0)')};
      };
      &::after {
        transform: ${({ showMenu }) => (showMenu ? 'translateY(-50%) rotate(-45deg)' : 'translateY(-50%) rotate(0)')};
      };
    }
`;
