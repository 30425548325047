import PropTypes from 'prop-types';
import React from 'react';

import { ContainerStyle } from '../../theme/index';

const Container = ({ /* title, */ children }) => (
  <ContainerStyle>
    {children}
  </ContainerStyle>
);
Container.propTypes = {
  children: PropTypes.node,
};


export default Container;
