import React, { useState } from 'react';

import Logo from '../../assets/images/stoomlink.svg';
import {
  LogoContainer,
  Container,
  SectionLink,
  InnerContainer,
  LinkContainer,
  LinkBlock,
  ButtonMenu,
} from './styledComponent';

const personasLinks = [
  // { title: 'B2B', href: '/enterprise' },
  // { title: 'B2G', href: '/cities' },
];

const productsLinks = [
  { title: 'Digital Signage', href: '/smartdisplays' },
  { title: 'Services & APIs', href: '/smartmobilityplanner' },
  { title: 'Broadcasting', href: '/broadcast' },
];

const otherlinks = [
  { title: 'About', href: '/team' },
];

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <Container showMenu={showMenu}>
      <InnerContainer>
        <LogoContainer href="/">
          <img src={Logo} height={20} />
        </LogoContainer>
        <LinkContainer showMenu={showMenu}>
          <LinkBlock>
            {personasLinks.map((link, i) => {
              return (
                <SectionLink
                  key={`${link.title + i}`}
                  href={link.href}
                  className="persona"
                >
                  {link.title}
                </SectionLink>
              );
            })}
          </LinkBlock>
          <LinkBlock>
            {productsLinks.map((link, i) => {
              return (
                <SectionLink
                  key={`${link.title + i}`}
                  href={link.href}
                >
                  {link.title}
                </SectionLink>
              );
            })}
          </LinkBlock>
          <LinkBlock className="otherLinks">
            {otherlinks.map((link, i) => {
              return (
                <a
                  key={`${link + i}`}
                  href={link.href}
                >
                  {link.title}
                </a>
              );
            })}
          </LinkBlock>
        </LinkContainer>
        <ButtonMenu
          onClick={() => {
            setShowMenu(!showMenu);
          }}
          showMenu={showMenu}
        >
          menu
        </ButtonMenu>
      </InnerContainer>
    </Container>
  );
};

export default Nav;
